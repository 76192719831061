import React from 'react';
import cls from 'classnames';

import { useEnvironment, useTranslation, useBi } from '@wix/yoshi-flow-editor';
import { useRouter } from '@wix/tpa-router/react';

import { groupsListButtonClick } from '@wix/bi-logger-groups-data/v2';

import type { TPAComponentProps } from 'wix-ui-tpa/dist/cssVars/types/types';

import {
  More as MoreIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@wix/wix-ui-icons-common/on-stage';

import { Menu, MenuItem } from 'wui/Menu';
import { Box } from 'wui/Box';
import { IconButton, IconButtonThemes } from 'wui/IconButton';

import { CancelEventDialog } from './CancelEventDialog';

import classes from './EventActions.scss';

interface EventActionsProps extends TPAComponentProps {
  groupId: string;
  eventId: string;
  eventSlug: string;
}

export function EventActions(props: EventActionsProps) {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const router = useRouter();
  const bi = useBi();

  const [isCancelDialogOpen, setIsCancelDialogOpen] = React.useState(false);

  return (
    <Box
      className={cls(classes.root, {
        [classes.mobile]: isMobile,
      })}
    >
      <Menu
        {...props}
        element={
          <IconButton
            theme={IconButtonThemes.Box}
            icon={<MoreIcon />}
            aria-label={t('groups-web.a11y.more-actions')}
          />
        }
      >
        <MenuItem
          prefixIcon={<EditIcon />}
          onClick={handleEditClick}
          content={t('groups-web.events.actions.edit.label')}
        />
        <MenuItem
          prefixIcon={<DeleteIcon />}
          onClick={handleCancelClick}
          content={t('groups-web.events.actions.cancel.label')}
        />
      </Menu>

      <CancelEventDialog
        eventId={props.eventId}
        onClose={closeCancelEventDialog}
        isOpen={isCancelDialogOpen}
      />
    </Box>
  );

  function closeCancelEventDialog() {
    setIsCancelDialogOpen(false);
  }

  function openCancelEventDialog() {
    setIsCancelDialogOpen(true);
  }

  function navigateToEditEvent() {
    router.go('group.events.edit', { eventSlug: props.eventSlug });
  }

  function handleEditClick() {
    reportMenuClick('edit');
    navigateToEditEvent();
  }

  function handleCancelClick() {
    reportMenuClick('delete');
    openCancelEventDialog();
  }

  function reportMenuClick(buttonName: string) {
    bi.report(
      groupsListButtonClick({
        button_name: buttonName,
        page_name: 'events_livesite',
        group_id: props.groupId,
      }),
    );
  }
}

EventActions.displayName = 'EventActions';
