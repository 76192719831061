import React from 'react';
import { useSelector } from 'react-redux';
import { useEnvironment, useTranslation, useBi } from '@wix/yoshi-flow-editor';

import {
  groupsModalButtonClick,
  groupsNotificationShownSrc111Evid510,
} from '@wix/bi-logger-groups-data/v2';

import { useDidUpdate } from 'common/hooks';
import { useController } from 'common/context/controller';

import { selectEventStatus } from 'store/selectors';

import { Button } from 'wui/Button';
import { AlertDialog } from 'wui/AlertDialog';
import { DialogTitle } from 'wui/DialogTitle';
import { DialogContent } from 'wui/DialogContent';
import { DialogContentText } from 'wui/DialogContentText';
import { DialogActions } from 'wui/DialogActions';

interface CancelDialogProps extends React.ComponentProps<typeof AlertDialog> {
  eventId: string;
}

export const CancelEventDialog: React.FC<CancelDialogProps> = (props) => {
  const { eventId, ...rest } = props;

  const { t } = useTranslation();
  const bi = useBi();
  const { isMobile } = useEnvironment();
  const { events$ } = useController();

  const statuses = useSelector(selectEventStatus(eventId));
  const { loading, error } = statuses.cancel;

  useDidUpdate(() => {
    if (!loading && !error) {
      bi.report(
        groupsNotificationShownSrc111Evid510({
          type: 'success',
          name: 'event_deleted',
        }),
      );
      props.onClose();
    }
  }, [loading, error]);

  return (
    <AlertDialog
      aria-labelledby="dg-dialog-title"
      aria-describedby="dg-dialog-content"
      {...rest}
    >
      <DialogTitle
        alert
        id="dg-dialog-title"
        title={t('groups-web.events.cancel-dialog.title')}
      />
      <DialogContent alert>
        <DialogContentText alert id="dg-dialog-content">
          {t('groups-web.events.cancel-dialog.content')}
        </DialogContentText>
      </DialogContent>
      <DialogActions alert>
        <Button
          outlined
          variant="basic"
          fullWidth={isMobile}
          onClick={handleGoBackClick}
        >
          {t('groups-web.events.cancel-dialog.actions.cancel')}
        </Button>
        <Button
          variant="basic"
          fullWidth={isMobile}
          disabled={loading}
          loading={loading}
          onClick={handleCancelEvent}
        >
          {t('groups-web.events.cancel-dialog.actions.confirm')}
        </Button>
      </DialogActions>
    </AlertDialog>
  );

  function handleGoBackClick() {
    bi.report(
      groupsModalButtonClick({
        button_name: 'go_back',
        origin: 'events_livesite',
        modal_name: 'delete_event',
      }),
    );
    props.onClose?.();
  }

  function handleCancelEvent() {
    bi.report(
      groupsModalButtonClick({
        button_name: 'cancel_event',
        origin: 'events_livesite',
        modal_name: 'delete_event',
      }),
    );
    events$.cancel(eventId);
  }
};

CancelEventDialog.displayName = 'CancelEventDialog';
